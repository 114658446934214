* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	/* font-family: helvetica neue; */
	text-decoration: none;
	list-style: none;
}

/* @import url('https://fonts.cdnfonts.com/css/helvetica-neue-9'); */
@import url('https://fonts.googleapis.com/css2?family=Itim&family=Just+Another+Hand&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700&display=swap');

body {
	font-family: Arial, Helvetica, sans-serif;
	font-weight: 400;
	font-family: 'inter';
	background-color: black !important;
	color: white;
}

.egopie-logo{
	width: 400px;
}
.header{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 20;
}
.header2{
	position: static;
	top: 0;
	left: 0;
	right: 0;
	z-index: 20;
}
.header .container{
	max-width: 1499px;
}
.navigation {
	width: 100%;
	height: 8rem;
	padding: 1rem 1.5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.navigation img {
	height: 6rem;
}
header .menu-list{
	position: absolute;
	z-index: 1;
	top: 100%;
	right: 0;
	background-color: #FFF;
	border-radius: 8px;
	border: 1px solid #636363;
	width: 200px;
	padding: 0px 0;
	display: block;
	overflow: hidden;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	margin-bottom: 0;
	
}
header .menu-list.hidden{
	display: none ;
}
header .menu-list a{
	text-decoration: none;
	padding: 10px 15px;
	display: block;
	font-size: 18px;
	font-weight: 500;
	color: #020202;
}
header .menu-list li:not(:last-child){
	border-bottom: 1px solid  #d9d9d9;
}
header .menu-list li:hover{
	background-color: lightblue;
	
}
.banner-wrapper{
	/* background: url('./assets/images/indexanimation.gif'); */
	background: url('./assets/images/banner-bg.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top;
}
.banner {
	height: 100vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: end;
	text-align: center;
	color: #FFF;
}

.animation {
	width: 100%;
	animation: FadeIn 2s both;
	display: flex;
	flex-direction: column;
}
.logo-text{
	font-size: 90px;
	line-height: 100px;
	margin-bottom: 50px;
	position: relative;
}
.logo-text::before{
	content: "";
	position: absolute;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background-color: #f0bb00;
	right: 43.2%;
	top: 20px;
	z-index: 2;
}

@keyframes FadeIn {
	0% {
		opacity: 0;
		transform: translateY(-50px);
	}

	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}

.animation h1 {
	text-align: center;
	font-weight: 500;
	margin-bottom: 24px;
	font-size: 45px;
	line-height: 1.2;
}

.animation p.anime-post-text {
	color: #FFF;
	text-align: center;
	font-size: 35px;
	font-weight: 300;
	margin-bottom: 0;
}

.animation .home-links {
	display: flex;
	justify-content: center;
	gap: 30px;
}

.animation a {
	color: #FFF;
	font-size: 40px;
	font-weight: 500;
	text-decoration: none;
	padding: 15px 20px;
	width: 250px;
	background-color: #002160;
	border: 2px solid #002160;
	border-radius: 50px;
	margin: 0 auto;
	margin-top: 25px;
	transition: all ease-in-out 0.3s;
}

.animation a:hover {
	background-color: #fff;
	color: #002160;

}

.fade {
	animation-name: fade;
	animation-duration: 2s;
}

@keyframes fade {
	from {
		opacity: .4
	}

	to {
		opacity: 0
	}
}

.list-product li{
	width: 100%;
	margin: 0 10px;
	position: relative;
	overflow: hidden;
	transition: all ease-in-out 0.3s;
}
.list-product li .product-img{
	overflow: hidden;
	cursor: pointer;
}
.list-product li .product-img img{
	transition: all ease-in-out 0.3s;
}
.list-product li .product-img:hover img{
	transform: scale(1.1);
	overflow: hidden;
}
.list-product .product-img{
	transition: all ease-in-out 0.3s;
	width: 100%;
	height: 380px;
}
.list-product .product-img::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0);
    top: 0;
    left: 0;
    z-index: 1;
	opacity: 0.3;
}
.list-product .product-img img{
	width: 100%;
	height: 100%;
}
.list-product .slogan{
	position: absolute;
	z-index: 2;
	left: 0;
	top: 65%;
	width: 100%;
	text-align: center;
	padding: 0 15px;
	font-size: 24px;
}
.list-product h3{
	position: relative;
	z-index: 11;
	color: #2abcf2;
	margin: 25px 0 0;
	text-align: center;
}
.product-heading{
	font-size: 50px;
	line-height: 55px;
}
.product-description h3{
	font-size: 40px;
	line-height: 45px;
}
.product-description p{
	font-size: 24px;
	line-height: 30px;
}
.product-description span{
	font-size: 28px;
	line-height: 32px;
	font-weight: 700;
}
.product-description .product-img{
	width: 50%;
}
.toggle-icon svg{
	width: 60px;
	height: 60px;
}
.product-wrapper{
	max-height: 100vh;
	overflow-y: scroll;
	overflow-x: hidden;
  	scroll-snap-type: y mandatory;
	scroll-margin: 20px;
}
.product-list-wrapper{
	padding-top: 0px;
	scroll-snap-align: start;
}
.product-list-wrapper2{
	padding-top: 90px;
	scroll-snap-align: start;
}
.career-section{
	 
	scroll-snap-align: start;
}
.customer-details-section{
max-width: 80vw;

}
.customer-section {
    height: 100vh;
    overflow-y: scroll;
   
    width: 100vw;
    display: flex;
    flex-direction: column;
    /* overflow: scroll; */
    align-items: center;
    /* justify-content: center; */
    padding-top: 68px;
	text-align: left;
}
.about-section{
	scroll-snap-align: start;
	width: 100vw;
	height: 100vh;
	overflow: auto;
	overflow-y: scroll;
	scroll-snap-type: ;
}
.aboutDescription div{
	 
}

.product-section{
	height: 100vh;
	width: 100vw;
	scroll-snap-align: end;
}
.product-section .product-bg{
	height: 100vh;
	width: 100%;
	/* object-fit: cover; */
}
.product-section .product-bg::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0);
    top: 0;
    left: 0;
    opacity: 0.4;
}
.product-section .content-wrapper{
	position: absolute;
	top: 80px;
	width: 100%;
	max-width: 1000px;
	margin: 0 auto;
	left: 50%;
	transform: translateX(-50%);
	padding: 0 30px;
}
.product-section h3{
	font-size: 60px;
	line-height: 65px;
}
.product-section p{
	font-size: 24px;
	line-height: 28px;
	text-align: center;
}
.product-section .slogan{
	color: #2abcf2;
    font-size: 28px;
    font-weight: 500;
}
.product-section .btn-wrapper{
	position: absolute;
	bottom: 80px;
	margin: 0 auto;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
}
.product-section .product-logo{
	width: 250px;
}
.product-section .button{
	padding: 15px 20px;
	font-size: 24px;
	line-height: 28px;
	font-weight: 700;
	border: 0;
	width: 300px;
	color: rgb(23, 26, 32);
	background-color: #e7e7e7;
	text-decoration: none;
	cursor: pointer;
}
.product-section .button:nth-child(2){
	margin-left: 30px;
	background-color: rgb(23, 26, 32);
	color: #FFF;
}
.footer{
	scroll-snap-align: center;
}
.main-footer {
    padding: 70px 0;
}
.ftr-logo {
	width: 230px;
    margin: 16px 0;
    display: inline-block;
}
.footer-nav{
	padding-left: 0;
}
.footer-nav li {
    margin-bottom: 10px;
    list-style-type: none;
}
.footer-nav li a {
    color: #838b8c;
    font-family: "Montserrat",sans-serif;
    font-weight: 400;
    font-size: 16px;
    display: block;
    text-transform: capitalize;
	text-decoration: none;
}
.footer-nav li a:hover {
    color: #03b6e7;
}
.footer-back-to-top{
	background-color: #37475A;
	text-align: center;
	padding: 20px;
	cursor: pointer;
	pointer-events: auto;
	position: relative;
	z-index: 30;
}
.footer-back-to-top:hover{
	background-color: #485769;
}
.footer-back-to-top span{
	color: #FFF;
	font-size: 20px;
	line-height: 23px;
}
.copyright-section{
	color: #838b8c;
    display: block;
    font-family: "Montserrat",sans-serif;
    font-size: 15px;
    font-weight: 400;
    padding: 18px 0 51px;
    border-top: 1px solid #323334;
}
@media (max-width: 991px) {
	.animation h1 {
		margin-bottom: 20px;
		font-size: 38px;
	}
	.animation a {
		font-size: 30px;
		padding: 15px;
		width: 230px;
		margin-top: 15px;
	}
	.egopie-logo {
		width: 300px;
	}
	.product-heading {
		font-size: 42px;
		line-height: 45px;
	}
	.list-product {
		flex-wrap: wrap;
		margin-bottom: 80px;
	}
	.list-product li{
		width: calc(50% - 20px);
		margin-bottom: 30px;
	}
	.list-product .product-img {
		height: 300px;
	}
	.product-section .button {
		font-size: 22px;
		line-height: 24px;
		width: 250px;
	}
	.ftr-logo {
		width: 200px;
	}
	.main-footer {
		padding: 50px 0;
	}
 /* media query for about page 	 */




}
@media (max-width: 575px){
	.egopie-logo {
		width: 250px;
	}
	.animation h1 {
		font-size: 30px;
	}
	.animation a {
		font-size: 28px;
		width: 210px;
	}
	.navigation img {
		height: 5rem;
	}
	.navigation {
		height: 6.5rem;
	}
	.toggle-icon svg {
		width: 50px;
		height: 50px;
	}
	.product-section .slogan {
		font-size: 26px;
	}
	.product-heading {
		font-size: 35px;
		line-height: 40px;
	}
	.product-section .button {
		font-size: 20px;
		line-height: 23px;
		width: 220px;
	}
	.list-product li{
		width: 100%;
		margin: 0 0 30px;
	}
	.product-section .btn-wrapper{
		flex-wrap: wrap;
	}
	.product-section .button:first-child{
		margin-bottom: 15px;
	}
	.product-section .button:nth-child(2){
		margin-left: 0;
	}
	.product-section p {
		font-size: 20px;
		line-height: 24px;
	}
	.product-section .btn-wrapper {
		bottom: 50px;
	}
	.product-section .product-logo {
		width: 200px;
	}
	.main-footer {
		padding: 40px 0;
	}
	.copyright-section {
		padding: 15px 0 20px;
	}
}
@media (max-width: 420px){
	.animation h1 {
		font-size: 28px;
	}
	.navigation {
		height: 6rem;
	}
	.navigation img {
		height: 4rem;
	}
	.product-section .product-logo {
		width: 180px;
	}
	.product-section p {
		font-size: 18px;
		line-height: 22px;
	}
}


/* css for about career faq pages  */
.card-container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 300px; /* Set the desired height for the cards */
	perspective: 1000px;
  }
  
  .card {
	width: 100%; /* Set the width to 100% of the container */
	height: 100%; /* Set the height to 100% of the container */
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #141212 !important;
	border: 1px solid #ccc;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	font-size: 24px;
	color: #333;
	text-align: center;
	/* height: 30px; */
  }
  .card-container{
	 
	position: relative;
  }
  .card-container {
 margin: 20px;
  width: 100%;
 
 /* width: 33.33%; */
  height: 380px; /* Set the desired height for the cards container */
  /* perspective: 200px; */
 
  box-shadow: 0px 2px 11px #1e8ebd;
}
.lightlayer::after{
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	background-color: #0000005f;
	width: 100%;
	height: 100%;
}
.react-card-flip{
	display: flex; 
	flex: 1;
 
	height: 100%;
}
.react-card-flipper{
	display: flex;border-radius: 150px;
	flex: 1;
	height: 100%;

}
.react-card-front{
	display: flex;
}
  .card img {
	max-width: 100%; /* Ensure the image fits within the card */
	max-height: 100%; /* Ensure the image fits within the card */
	object-fit: cover;
	/* display: block; */
	width: 100%;
	height: 100%;
  }
  
.card p{
	color: #fff;
	/* font-size: 2rem; */
}
.card-heading{
	font-size: 4rem;
	position: absolute;
	/* top: 0; */
	/* bottom: 0; */
}
.aboutCards{
 
}
.white-100{
	color: #fff;
}
.aboutcompany{
	display: flex;
	justify-content: center;
	background-color: #012535;
	 margin: auto auto;

}

.aboutcomp-sub{
	display: flex;
	justify-content:center;
	align-items: flex-start;
 
	max-width: 80vw;
 
}
.aboutsideimg{
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
min-height: 520px;
height: 50vh;
 
}
.aboutsideimg img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.aboutsidetext h3{
	font-size: 1.8rem;
}
.aboutsidetext p{
	font-size:1rem;
}
.aboutcompany{
	padding: 20px;
}
.aboutcompany2{
	display: flex;
	justify-content: center;
	background-color: #012535;
	align-items: center;
	
}
/* Addition to aboutcompany2 above */
.aboutcompany2{
	padding: 20px;
	text-align: center;
}
.ourBDesc2 {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: justify;
	padding: 10px;
 }
 .ourBDesc2 .col-1{
max-width: 100%;
width: 60%;
	font-size: 1.4rem;
	padding: 20px;
	}
.ourBDesc2 p{
		font-size:1rem;
		/* line-height: 1.3rem; */
	}
.twoRowDesc2 {
		display: flex;
	}
.twoRowDesc2 .col-1{
	width: 50%;

	}
.aboutsidetext{
	margin: 10px;
	padding-right: 20px;
}
.about-wrapper{
	max-height: 100vh;
}
.aboutDescription{
	margin-top: 60px;
	 color: #fff;
	 display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #002b3d;		
	 
}
.aboutDescription h1{
	text-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
}
.aboutDescription .row-1 .col-1{
	
	
	 
	padding: 20px;
}
.aboutDescription .row-1{
	max-width: 80vw;
	padding-top: 10px;  
    
    margin-bottom: 3px;
    
}
 .ourBDesc{
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: justify;
	padding: 10px;
 }
 .ourBDesc .col-1{
max-width: 100%;
width: 60%;
	font-size: 1.4rem;
	padding: 20px;
	}
.ourBDesc p{
		font-size:1rem;
		/* line-height: 1.3rem; */
	}
.twoRowDesc{
		display: flex;
	}
.twoRowDesc .col-1{
	width: 50%;

	}
	.customertext{
		border: none;
		box-shadow: none;
	}

	.cDetailImage{
		width: 200px;
		height: 200px;
		 
	}
	.customerDetails{
		max-width: 80vw;
		background-color: #012535 !important;
	}
	.profile_details{
		background-color: #012e41 !important;
	}
	.customerDescrpt{
	 
	}
	.customeraboutinfo{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;	
		}
	.cDetailImage img{
		width: 100%;
		height: 100%;
		object-fit: cover;
		display: block;
		border-radius: 50%;
		/* #052e7a */
	}
	.customeraboutinfo div:nth-child(even){
		/* background-color: rgb(35, 83, 226); */
		flex-direction: row-reverse;

	}
	.customerDetails div{
		/* flex-direction: row-reverse; */
	}
	.customerbasemore {
		 
		padding: 20px;
	  }
	  
	  .sector-button {
		background: none;
		border: none;
		cursor: pointer;
		font-size: 20px;
	  }
	  
	  .sector-text {
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.5s ease-in-out;
		color: #fff;
		margin-top: 14px;
	  }
	  
	  .sector-text.visible {
		max-height: 200px; /* Adjust the max-height based on your content */
	  }
	  
	 .sector-text ul {
		list-style: none;
	 
	  }
	  
	 .sector-text ul li {
		margin: 5px 0;
	  }
	  .sector-text ul li::before{
  
		 
		content: "\00BB";
		padding-right: 5px;
	}
	  
  
	  .cDetailsbox{
		background-color:  #012535 !important;
	  }
	  .sector-header{
		font: 1em sans-serif;
		font-weight: bold;
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: 0px 0xp gray;		
		 
	  }
	  .sector-header :hover{
		color: red;
	  }
	  .sector-header span:hover{
		color: #ff1b1b;
	  }
	  .sector-header span{
	 
	  }
	  .sector-header span:last-child{
		font-size: 1.2em;
	  }

	  /* css for about slider  */
	  .slider-container {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 320px;
		overflow: hidden;
	  }
	  
	  .slider-item {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: start;
		background-size: cover;
		background-position: center;
		opacity: 1;
		transition:  2s ease-in-out; /* Adjust the transition duration to your liking */
		animation: fadeIn 2s;
	  }
	  .slider-text{
		animation: fadeIn 1s;
		/* padding: 10px; */
		/* background-color: red; */
		margin-left: 5vw;
		background-color: #0001033c;
		width: 100%;
		text-align: left;
		
	  }
	  .slider-text h2{
		padding: 0;
		margin: 0;
		margin-bottom: 5px;
	  }
	  .slider-text p{
		font-size: 1.1rem;
		padding-top: 10px;
	  }
	  .fade-enter {
		/* opacity: 0; */
		 
	  }
	  
	  .fade-enter-active {
		opacity: 1;
		transition: opacity 2s ease-in-out;
	  }
	  
	  .fade-exit {
		opacity: 1;
	  }
	  
	  .fade-exit-active {
		opacity: 0;
  transition: opacity 2s ease-in-out;
	  }
	  
	  .arrow-button {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		background: none;
		border: none;
		font-size: 24px;
		cursor: pointer;
		padding: 10px;
		color: #333;
	  }
	  
	  .left {
		left: 10px;
	  }
	  
	  .right {
		right: 10px;
	  }
	  @keyframes fadeIn {
		0% { opacity: 0; }
		100% { opacity: 1; }
	  }

	  /* css for about contact us section  */
	  .contact-us-container {
		background-image: url(https://images.pexels.com/photos/33999/pexels-photo.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
		background-size: cover;
		background-position: center;
		color: white;
		align-items: center;
		padding: 50px 0;
		width: 100%;
		text-align: center;
		height: 100%;
		display: flex;
		justify-content: center;
		max-height: 100vh
	}
	  
	  .contact-content {
		background-color: rgba(0, 0, 0, 0.7);
		padding: 20px;
		border-radius: 10px;
		max-width: 80%;
		margin: 0 auto;
		width: 80vw;
	  }
	  
	 .contact-content h2 {
		font-size: 24px;
		margin-bottom: 10px;
	  }
	  
	.contact-content  p {
		font-size: 16px;
		margin-bottom: 20px;
	  }
	  
	.contact-content p  a {
		color: #fff;
		text-decoration: none;
		font-weight: bold;
	  }
	  
	 .contact-content p a:hover {
		text-decoration: underline;
	  }
	  
	   
 /* AOS animations */
[data-aos] {
	opacity: 0; /* Initial opacity */
	transition: opacity 0.5s ease-in-out;
  }
  
  [data-aos].aos-animate {
	opacity: 1; /* Final opacity when the animation is applied */
  }
  .customer-details {
	transform: translateX(-100%); /* Initially outside the viewport on the left */
	transition: transform 1s ease-in-out;
  }
  
  .fade-in {
	opacity: 1; /* Fade in when inView is true */
	transform: translateX(0);
  }
  
  @media (max-width: 1100px){
	.customerDetails{
		/* max-width: 95vw; */
	 
	}
	.aboutDescription .row-1 {
		/* max-width: 95vw; */
	 
	}
  }
  @media (max-width: 760px){
	.customerDetails div {
		width: 75% !important;
		display: flex;
	}
	.customerDetails{
		flex-direction: column;
	}
	.customeraboutinfo div:nth-child(even) {
		/* background-color: rgb(35, 83, 226); */
		flex-direction: column;
	}
	.cDetailsbox{
		padding: 1rem !important;
	}
	 
  }

  @media (max-width: 760px){
	.aboutcomp-sub{
		flex-direction: column-reverse;
	}
	.aboutcomp-sub div{
		width: 100% !important;
	}
	.ourBDesc .col-1 {
	 
		width: 100%;
		 
	}
	.about-section {
	 
		height: auto;
	 
	}
  }
  @media (max-width: 567px){}
  .customer-details div{
	/* width: 90% !important; */
  }
  @media (max-width: 567px){

	.careersideimg {
		 
		min-height: 342px !important;
	}
.customerDetails div {
    width: 90% !important;
    display: flex;
}
}
@media (max-width: 425px){
.slider-text{
width: 160% !important;
margin: 0 !important;
}
.aboutDescription .row-1 {
     
    flex-direction: column;
}
.aboutDescription .row-1 div {
width: 100% !important;
}

.customerDetails div {
   flex-direction: column;
   align-items: center;
   justify-content: center;
}
.customer-details div .cDetailImage{
	width: 61% !important;
	
	height: 42vw
}
.customer-details div .cDetailsbox{
	text-align: center;
}
.aboutsideimg{
	min-height: 314px;
}
.careersideimg {
    min-height: 229px !important;
}
}

/* css for career page  */
.career-container{
	background-color: black;
}
.careersideimg{
	display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    min-height: 530px;
     
}
.careersideimg img{
width: 100%;
height: 100%;
object-fit: cover;
}
.career-top-sub{
	align-items: center;
}
.career-top-desc h6{
	font-size: 1rem;
	margin-top: 24px;
	letter-spacing: 1px;

}
.career-head-text{
	color: #00ffe5 !important;
}
.career-head-subheading{

	color:#00ffef;
}
.career-top-desc{
	width: 40% !important;
}
.career-top-img{
	width: 60% !important;
}
.career-form form{
/* background-color: #00ffe5; */
padding: 5px 5px;
width: 90%;
	background-color:#001822;
	
}
.career-section{
 
}

.career-form {
    display: flex;
    /* background-color: #011b2f; */
    justify-content: center;
    align-items: center;
    padding: 30px;
	padding-bottom: 50px;
	padding-top: 40px;
}
.flip-front-head h3{
	/* margin: 20px 5px !important; */
	width: 100%;
	}
	.flip-front-head{
		width: 100%;
	}
.flip-front-main{
	background-color: red;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	 
}
.flip-front-main-button button{
	border: none;
    border-radius: 10px;
    box-shadow: 0px 0px 13px #d6d6d6;
}
.flip-back-details p, .flip-back-details ul li{
	font-size:16px;
	text-align: left;
	list-style:circle;
	/* color: #005a81; */

}
.flip-back-details{
	padding-right: 14px;
	padding-left: 12px;
	height: 100%;
 
}
.flip-fornt-details p{
	color: #002b3d;
	color: #463232;
}
.career-flips-style{
	 
	/* width: 100%; */
	display: flex;
	flex-wrap: wrap;
}
.flip-card-container{
	width: 238px;
 
}

.flip-back-details,.font-card-careers{
	
	background-color: #e3f8ff !important;
	/* background-color: #6ddbff !important; */
	/* background-color: #09abe1 !important; */
	background: linear-gradient(#1691ba,#61d7ff) !important;
}
.flip-front-head {
	background-color: #001e2c !important;
}
.flip-front-head h3{
	color: #005a81;
	color: #fff;
}
/* #012535 */
.button-container {
	 
	margin-top: 50px;
  }

  .view-jobs-button {
	display: inline-block;
	padding: 10px 20px;
	background-color: #007BFF;
	color: #fff;
	font-weight: bold;
	text-align: center;
	text-decoration: none;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
	transition: background-color 0.3s ease, transform 0.3s ease;
  }

  .view-jobs-button:hover {
	background-color: #0056b3;
	transform: translateX(5px); /* Move the button 5px to the right on hover */
  }

  .arrow {
	display: inline-block;
	margin-left: 10px;
	position: relative;
	animation: arrowBounce 1s infinite;
  }

  .arrow::before {
	content: "\2192"; /* Right arrow character */
	font-size: 18px;
	line-height: 1;
	vertical-align: middle;
  }

  @keyframes arrowBounce {
	0%, 20%, 50%, 80%, 100% {
	  transform: translateY(0);
	}
	40% {
	  transform: translateY(-10px);
	}
	60% {
	  transform: translateY(-5px);
	}
  }
  .twoRowDesc div p ,.ourBDesc div p{
	 
  }
  .aboutsidetext p {
/* /?	font-size: 12px ; */
  }
  .about-section{
	scroll-snap-align: start;
  }
  .customeraboutinfo {
	width: 100vw;
	height: auto;
	overflow: auto;
	/* padding-bottom: 250px; */
  }
  .career-wrapper-section{
	  scroll-snap-align: start;
  }
  .contact.wrapper-section{
	  scroll-snap-align:start;
	  scroll-snap-type: none;
	  max-height: 100vh;
	overflow-y: scroll;
	overflow-x: hidden;
	scroll-margin: 20px;
  }
  .career.wrapper-section{
	  scroll-snap-align:start;
	  scroll-snap-type: none;
	  max-height: 100vh;
	scroll-margin: 20px;
  }
  .career-product-wrapper2{
	max-height: 100vh;
	overflow-y: scroll;
	overflow-x: hidden;
  	scroll-snap-type: none;
	scroll-margin: 20px;
  }
  .about-wrapper-section2{
	  scroll-snap-align:start;
	  scroll-snap-type: y mandatory;
	  max-height: 100vh;
	scroll-margin: 20px;
  }
  /* Upper Product Scroll I won't forget to use it*/
  .product-wrapper2{
	  scroll-snap-type:none;
	  max-height: 100vh;
	overflow-y: scroll;
	overflow-x: hidden;
  	scroll-snap-type: none;
	scroll-margin: 20px;
  }
  .about-wrapper-section3{
	max-height: 100vh;
	overflow-y: scroll;
	overflow-x: hidden;
  }
  .customer-base{
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	max-height: 100vh;
}
